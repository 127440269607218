.content {
  font-family: 'Gilmer';
}

h2 {
  color: #757575;
  font-weight: 900;
}

span {
  font-size: 13px;
  margin: 0;
  padding: 0;
}

strong {
  font-size: 15px;
  margin-bottom: 10px;
  color: #686868;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 0;
}

.radio-button {
  margin: 0 0 0.25em 0;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  color: #292321;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

input[type="radio"] + label span {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

input[type="radio"] + label span {
  background-color: transparent;
  border: 2px solid #999;
}

input[type="radio"]:checked + label span {
  background-color: #00bf91;
  border: 2px solid transparent;
}

input[type="radio"] + label span,
input[type="radio"]:checked + label span {
  -webkit-transition: background-color 0.4s linear;
  -o-transition: background-color 0.4s linear;
  -moz-transition: background-color 0.4s linear;
  transition: background-color 0.4s linear;
}

.default-input {
  border: 1px solid #999;
  border-radius: 7px;
  font-size: 13px;
  padding: 5px 0 5px 5px;
  outline: none;
}
