.product-row {
  border-bottom: 1px solid #A8A8A8;
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.cart-grid::-webkit-scrollbar {
  width: 10px;
}

.cart-grid::-webkit-scrollbar-track {
  background: transparent;
  border-bottom-right-radius: 5px;
  margin-bottom: 1px;
}

.cart-grid::-webkit-scrollbar-thumb {
  background: #e0e0e0;
  border-radius: 5px;
}

.cart-grid::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -7px 9.3333333333px #f7ff00, -74px -243.6666666667px #00ff5e, 231px -244.6666666667px #ff6600, 66px -45.6666666667px #ff00ee, -57px -22.6666666667px #0066ff, 220px -43.6666666667px #00ff33, -35px -170.6666666667px #ff5e00, -37px -145.6666666667px #ff0073, -26px 62.3333333333px #f700ff, 223px -146.6666666667px #eeff00, 79px -153.6666666667px #e6ff00, -111px -162.6666666667px #00ff09, 44px -123.6666666667px #aa00ff, 47px -123.6666666667px #00ff4d, 203px 30.3333333333px #48ff00, -15px 47.3333333333px #0009ff, -85px -354.6666666667px #2200ff, 156px -313.6666666667px #dd00ff, -12px -68.6666666667px #ff8800, -9px -222.6666666667px #00ff9d, 159px -372.6666666667px #d9ff00, 37px -312.6666666667px #d5ff00, 59px 2.3333333333px #f7ff00, 213px -260.6666666667px yellow, -52px -178.6666666667px #d9ff00, 224px -299.6666666667px #00ff7b, 160px -180.6666666667px #ff009d, 234px -84.6666666667px #00ffb3, -124px 25.3333333333px #0051ff, 159px -108.6666666667px #ff0004, 48px 18.3333333333px #3c00ff, -48px 61.3333333333px #ff0051, 162px -90.6666666667px #00e1ff, -60px -113.6666666667px #ffbf00, 79px -311.6666666667px #ff00cc, -234px -77.6666666667px #4400ff, 78px -20.6666666667px #22ff00, 36px -191.6666666667px #3c00ff, 83px -318.6666666667px #008cff, -92px 21.3333333333px #8000ff, 59px -24.6666666667px #00b7ff, 237px -126.6666666667px #ffd900, -218px -256.6666666667px darkorange, 21px -390.6666666667px #a200ff, 156px -309.6666666667px #ff0011, -101px -408.6666666667px #001aff, 42px 54.3333333333px #ff0900, 139px 73.3333333333px #6200ff, -181px -40.6666666667px #c400ff, -34px 60.3333333333px #0011ff, 190px -295.6666666667px #bf00ff;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: -7px 9.3333333333px #f7ff00, -74px -243.6666666667px #00ff5e, 231px -244.6666666667px #ff6600, 66px -45.6666666667px #ff00ee, -57px -22.6666666667px #0066ff, 220px -43.6666666667px #00ff33, -35px -170.6666666667px #ff5e00, -37px -145.6666666667px #ff0073, -26px 62.3333333333px #f700ff, 223px -146.6666666667px #eeff00, 79px -153.6666666667px #e6ff00, -111px -162.6666666667px #00ff09, 44px -123.6666666667px #aa00ff, 47px -123.6666666667px #00ff4d, 203px 30.3333333333px #48ff00, -15px 47.3333333333px #0009ff, -85px -354.6666666667px #2200ff, 156px -313.6666666667px #dd00ff, -12px -68.6666666667px #ff8800, -9px -222.6666666667px #00ff9d, 159px -372.6666666667px #d9ff00, 37px -312.6666666667px #d5ff00, 59px 2.3333333333px #f7ff00, 213px -260.6666666667px yellow, -52px -178.6666666667px #d9ff00, 224px -299.6666666667px #00ff7b, 160px -180.6666666667px #ff009d, 234px -84.6666666667px #00ffb3, -124px 25.3333333333px #0051ff, 159px -108.6666666667px #ff0004, 48px 18.3333333333px #3c00ff, -48px 61.3333333333px #ff0051, 162px -90.6666666667px #00e1ff, -60px -113.6666666667px #ffbf00, 79px -311.6666666667px #ff00cc, -234px -77.6666666667px #4400ff, 78px -20.6666666667px #22ff00, 36px -191.6666666667px #3c00ff, 83px -318.6666666667px #008cff, -92px 21.3333333333px #8000ff, 59px -24.6666666667px #00b7ff, 237px -126.6666666667px #ffd900, -218px -256.6666666667px darkorange, 21px -390.6666666667px #a200ff, 156px -309.6666666667px #ff0011, -101px -408.6666666667px #001aff, 42px 54.3333333333px #ff0900, 139px 73.3333333333px #6200ff, -181px -40.6666666667px #c400ff, -34px 60.3333333333px #0011ff, 190px -295.6666666667px #bf00ff;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: -7px 9.3333333333px #f7ff00, -74px -243.6666666667px #00ff5e, 231px -244.6666666667px #ff6600, 66px -45.6666666667px #ff00ee, -57px -22.6666666667px #0066ff, 220px -43.6666666667px #00ff33, -35px -170.6666666667px #ff5e00, -37px -145.6666666667px #ff0073, -26px 62.3333333333px #f700ff, 223px -146.6666666667px #eeff00, 79px -153.6666666667px #e6ff00, -111px -162.6666666667px #00ff09, 44px -123.6666666667px #aa00ff, 47px -123.6666666667px #00ff4d, 203px 30.3333333333px #48ff00, -15px 47.3333333333px #0009ff, -85px -354.6666666667px #2200ff, 156px -313.6666666667px #dd00ff, -12px -68.6666666667px #ff8800, -9px -222.6666666667px #00ff9d, 159px -372.6666666667px #d9ff00, 37px -312.6666666667px #d5ff00, 59px 2.3333333333px #f7ff00, 213px -260.6666666667px yellow, -52px -178.6666666667px #d9ff00, 224px -299.6666666667px #00ff7b, 160px -180.6666666667px #ff009d, 234px -84.6666666667px #00ffb3, -124px 25.3333333333px #0051ff, 159px -108.6666666667px #ff0004, 48px 18.3333333333px #3c00ff, -48px 61.3333333333px #ff0051, 162px -90.6666666667px #00e1ff, -60px -113.6666666667px #ffbf00, 79px -311.6666666667px #ff00cc, -234px -77.6666666667px #4400ff, 78px -20.6666666667px #22ff00, 36px -191.6666666667px #3c00ff, 83px -318.6666666667px #008cff, -92px 21.3333333333px #8000ff, 59px -24.6666666667px #00b7ff, 237px -126.6666666667px #ffd900, -218px -256.6666666667px darkorange, 21px -390.6666666667px #a200ff, 156px -309.6666666667px #ff0011, -101px -408.6666666667px #001aff, 42px 54.3333333333px #ff0900, 139px 73.3333333333px #6200ff, -181px -40.6666666667px #c400ff, -34px 60.3333333333px #0011ff, 190px -295.6666666667px #bf00ff;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: -7px 9.3333333333px #f7ff00, -74px -243.6666666667px #00ff5e, 231px -244.6666666667px #ff6600, 66px -45.6666666667px #ff00ee, -57px -22.6666666667px #0066ff, 220px -43.6666666667px #00ff33, -35px -170.6666666667px #ff5e00, -37px -145.6666666667px #ff0073, -26px 62.3333333333px #f700ff, 223px -146.6666666667px #eeff00, 79px -153.6666666667px #e6ff00, -111px -162.6666666667px #00ff09, 44px -123.6666666667px #aa00ff, 47px -123.6666666667px #00ff4d, 203px 30.3333333333px #48ff00, -15px 47.3333333333px #0009ff, -85px -354.6666666667px #2200ff, 156px -313.6666666667px #dd00ff, -12px -68.6666666667px #ff8800, -9px -222.6666666667px #00ff9d, 159px -372.6666666667px #d9ff00, 37px -312.6666666667px #d5ff00, 59px 2.3333333333px #f7ff00, 213px -260.6666666667px yellow, -52px -178.6666666667px #d9ff00, 224px -299.6666666667px #00ff7b, 160px -180.6666666667px #ff009d, 234px -84.6666666667px #00ffb3, -124px 25.3333333333px #0051ff, 159px -108.6666666667px #ff0004, 48px 18.3333333333px #3c00ff, -48px 61.3333333333px #ff0051, 162px -90.6666666667px #00e1ff, -60px -113.6666666667px #ffbf00, 79px -311.6666666667px #ff00cc, -234px -77.6666666667px #4400ff, 78px -20.6666666667px #22ff00, 36px -191.6666666667px #3c00ff, 83px -318.6666666667px #008cff, -92px 21.3333333333px #8000ff, 59px -24.6666666667px #00b7ff, 237px -126.6666666667px #ffd900, -218px -256.6666666667px darkorange, 21px -390.6666666667px #a200ff, 156px -309.6666666667px #ff0011, -101px -408.6666666667px #001aff, 42px 54.3333333333px #ff0900, 139px 73.3333333333px #6200ff, -181px -40.6666666667px #c400ff, -34px 60.3333333333px #0011ff, 190px -295.6666666667px #bf00ff;
  }
}
@keyframes bang {
  to {
    box-shadow: -7px 9.3333333333px #f7ff00, -74px -243.6666666667px #00ff5e, 231px -244.6666666667px #ff6600, 66px -45.6666666667px #ff00ee, -57px -22.6666666667px #0066ff, 220px -43.6666666667px #00ff33, -35px -170.6666666667px #ff5e00, -37px -145.6666666667px #ff0073, -26px 62.3333333333px #f700ff, 223px -146.6666666667px #eeff00, 79px -153.6666666667px #e6ff00, -111px -162.6666666667px #00ff09, 44px -123.6666666667px #aa00ff, 47px -123.6666666667px #00ff4d, 203px 30.3333333333px #48ff00, -15px 47.3333333333px #0009ff, -85px -354.6666666667px #2200ff, 156px -313.6666666667px #dd00ff, -12px -68.6666666667px #ff8800, -9px -222.6666666667px #00ff9d, 159px -372.6666666667px #d9ff00, 37px -312.6666666667px #d5ff00, 59px 2.3333333333px #f7ff00, 213px -260.6666666667px yellow, -52px -178.6666666667px #d9ff00, 224px -299.6666666667px #00ff7b, 160px -180.6666666667px #ff009d, 234px -84.6666666667px #00ffb3, -124px 25.3333333333px #0051ff, 159px -108.6666666667px #ff0004, 48px 18.3333333333px #3c00ff, -48px 61.3333333333px #ff0051, 162px -90.6666666667px #00e1ff, -60px -113.6666666667px #ffbf00, 79px -311.6666666667px #ff00cc, -234px -77.6666666667px #4400ff, 78px -20.6666666667px #22ff00, 36px -191.6666666667px #3c00ff, 83px -318.6666666667px #008cff, -92px 21.3333333333px #8000ff, 59px -24.6666666667px #00b7ff, 237px -126.6666666667px #ffd900, -218px -256.6666666667px darkorange, 21px -390.6666666667px #a200ff, 156px -309.6666666667px #ff0011, -101px -408.6666666667px #001aff, 42px 54.3333333333px #ff0900, 139px 73.3333333333px #6200ff, -181px -40.6666666667px #c400ff, -34px 60.3333333333px #0011ff, 190px -295.6666666667px #bf00ff;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
