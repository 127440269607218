.react-autosuggest__input {
  width        : 100%;
  height       : 40px;
  border       : 1px solid #707070;
  color        : #707070;
  font-family  : Gilmer;
  font-size    : 16px;
  cursor       : pointer;
  border-radius: 10px;
  outline      : none;
  text-align   : center;
  padding      : 0px;
}

.react-autosuggest__input--focused {
  border-color: #00BF91;
}

.react-autosuggest__input:disabled{
  background-color: #c5c1c1;
}

.react-autosuggest__suggestions-container--open {
  position        : absolute;
  display         : flex;
  z-index         : 99;
  width           : 99.6%;
  border          : 1px solid #00BF91;
  border-radius   : 0px 0px 10px 10px;
  border-top      : none;
  align-items     : center;
  background-color: white;
  justify-content : center;
}

.react-autosuggest__container--open {
  position: relative;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-radius: 10px 10px 0px 0px;
  border-bottom: 0px;
}

.react-autosuggest__suggestion {
  cursor: pointer;
}

.react-autosuggest__suggestions-list {
  overflow       : auto;
  width          : 100%;
  align-items    : center;
  display        : flex;
  flex-direction : column;
  justify-content: center;
  max-height     : 200px;
}

.highlighted{
  color : #00BF91
}

.empty{
  border-color: red;
}

.input-container{
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 100%;
}

.icon{
  position: absolute;
  right: 10px;
}